.news {
    @include pageBg;
    padding: px2rem(80px) 0;
    .wp {
        @include wp(1400px);
    }

    &-content {
        margin-top: 46px;
    }
    &-info {
        padding: 0 px2vw(65) 0 px2vw(45);
        flex: 1;
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding: px2rem(40px) 0 0;
        }
        .title {
            font-size: 20px;
            color: $fontColor333;
            margin-bottom: 20 / 704 * 100%;
            transition: all .3s;
            @include textEllipsis;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(36px);
            }
        }

        .desc {
            font-size: 15px;
            color: $fontColor666;
            line-height: 2;
            margin-bottom: 33 / 704 * 100%;
            max-height: 90px;
            @include multiEllipsis(3);
            @media screen and (max-width: 1024px) {
                font-size: px2rem(28px);
            }
        }

        .date {
            font-size: 14px;
            color: $fontColor666;
        }
    }
    &-swiper {
        overflow: hidden;
        background-color: #fff;
        padding: 48px;
        position: relative;
        border: 2px solid #fff;
        transition: all .4s;
        @include anime;
        @media screen and (max-width: 1024px) {
            padding: px2rem(40px) px2rem(30px) px2rem(100px);
        }
        &-item {
            display: flex;
            align-items: center;
            background-color: #fff;
            transition: all .4s;
            @media screen and (max-width: 1024px) {
                flex-flow: column;
            }
        }
        &:hover {
            border-color: $pageNavColor;
            background-color: #fff9f7;
            .news-swiper-item {
                background-color: #fff9f7;
            }
            .news-swiper-img {
                img {
                    transform: scale(1.05);
                }
            }
            .news-info {
                .title {
                    color: $pageNavColor;
                }
            }
        }

        &-img {
            width: 550 / 1300 * 100%;
            flex-shrink: 0;
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
            .img-box {
                overflow: hidden;
                @include zoomImg(550px, 300px);
            }
        }


        &-pagination {
            position: absolute;
            z-index: 10;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-flex;
            flex-flow: column;
            width: auto !important;
            left: auto !important;
            @media screen and (max-width: 1024px) {
                flex-flow: row;
                top: auto;
                left: 0 !important;
                right: auto;
                bottom: px2rem(30px);
                width: 100% !important;
                justify-content: center;
                transform: translateY(0);
            }
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin: 6px 0;
                opacity: 1;
                background-color: $baseColor;
                transition: all .4s;
                @media screen and (max-width: 1024px) {
                    margin: 0 px2rem(12px);
                }
            }
            .swiper-pagination-bullet-active {
                background-color: $pageNavColor;
            }
        }
    }

    &-list {
        display: flex;
        flex-flow: wrap;
        margin-top: 20px;
        margin-bottom: 57px;
        li {
            @include anime;
            width: 50%;
            box-sizing: border-box;
            margin-bottom: 20px;
            @media screen and (min-width: 1024px) {
                &:nth-child(odd) {
                    padding-right: 10px;
                }
                &:nth-child(even) {
                    padding-left: 10px;
                }
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
        &-item {
            display: block;
            background-color: #fff;
            border: 2px solid #fff;
            height: 100%;
            padding: 32px 54px 40px 36px;
            box-sizing: border-box;
            transition: all .4s;
            @media screen and (max-width: 1024px) {
                padding: px2rem(40px) px2rem(30px) px2rem(60px);
            }
            .news-info {
                padding: 0;
                .desc {
                    @include multiEllipsis;
                    max-height: 60px;
                }
                .date-box {
                    display: flex;
                    justify-content: space-between;
                    .more {
                        display: flex;
                        align-items: center;
                        color: $pageNavColor;
                        opacity: 0;
                        transform: translateX(50%);
                        transition: all .4s;
                        visibility: hidden;
                        line-height: 1;
                        &::after {
                            content: '\e63e';
                            font-family: 'iconfont';
                            color: $pageNavColor;
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                }
            }
            &:hover {
                border-color: $pageNavColor;
                background-color: #fff9f7;
                img {
                    transform: scale(1.05);
                }
                .news-info {
                    .title {
                        color: $pageNavColor;
                    }
                    .date-box {
                        .more {
                            opacity: 1;
                            transform: translateX(0);
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    &-img-list {
        display: flex;
        flex-flow: wrap;
        margin-top: 20px;
        margin-bottom: 57px;
        li {
            @include anime;
            width: 50%;
            box-sizing: border-box;
            margin-bottom: 20px;
            @media screen and (min-width: 1024px) {
                &:nth-child(odd) {
                    padding-right: 10px;
                }
                &:nth-child(even) {
                    padding-left: 10px;
                }
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
        .news-list-item {
            display: flex;
            padding: 25px 40px 25px 25px;
            @media screen and (max-width: 1024px) {
                flex-flow: column;
                padding: px2rem(30px) px2rem(30px) px2rem(80px);
            }
            .news-img {
                width: 276 / 690 * 100%;
                height: 150px;
                flex-shrink: 0;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
                .img-box {
                    height: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: .4s;
                    }
                }
            }
            .news-info {
                flex: 1;
                padding-left: 30px;
                @media screen and (max-width: 1024px) {
                    padding-left: 0;
                    margin-top: px2rem(30px);
                }
                .title {
                    @include multiEllipsis;
                    white-space: normal;
                    max-height: 66px;
                    line-height: 33px;
                }
                .more {
                    font-size: 14px;
                    color: $fontColor666;
                    margin-top: 14px;
                }
            }
        }
    }
}