.business {
    &-banner {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        height: 1000px;
        @media screen and (max-width: 1024px) {
            height: auto;
            padding-top: px2rem(200px);
            padding-bottom: px2rem(100px);
        }
        .wp {
            @include wp(1300px);
        }
        &-content {
            color: #fff;
            padding-top: 275px;
            padding-right: px2vw(326);
            @media screen and (max-width: 1024px) {
                padding-top: 0;
                padding-right: 0;
            }
            .title {
                font-size: 36px;
                display: flex;
                flex-flow: column;
                margin-bottom: 40px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(60px);
                    margin-bottom: px2rem(50px);
                }
                &::after {
                    content: '';
                    width: 50px;
                    height: 5px;
                    background-color: #fff;
                    margin-top: 30px;
                    @media screen and (max-width: 1024px) {
                        margin-top: px2rem(40px);
                    }
                }
            }
            .s-title {
                font-size: 26px;
                margin-bottom: 15px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(42px);
                }
            }
            .desc {
                font-size: 16px;
                line-height: 40px;
                img {
                    max-width: 100%;
                }
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(28px);
                    line-height: 2;
                }
            }
        }

        &-counter {
            display: flex;
            align-items: center;
            margin-top: px2rem(86px);
            @media screen and (max-width: 1024px) {
                flex-flow: wrap;
            }
            .item {
                font-size: 30px;
                font-family: Source Han Sans CN, Microsoft YaHei;
                padding: 0 70px;
                position: relative;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(42px);
                    padding: 0 px2rem(30px);
                    width: 50%;
                    box-sizing: border-box;
                    margin-bottom: 50px;
                    text-align: center;
                }
                &:not(:last-child) {
                    &::after {
                        content: '';
                        width: 1px;
                        height: 120px;
                        background-color: rgba(#fff, .1);
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
                &:first-child {
                    padding-left: 0;
                }
            }
            .num-box {
                display: flex;
                align-items: baseline;
                justify-content: center;
                line-height: 1;
                .num {
                    font-size: 75px;
                    font-family: 'LeagueGothic';
                    @media screen and (max-width: 1024px) {
                        font-size: px2rem(120px);
                    }
                }
                .s {
                    font-size: 15px;
                }
            }
            .w {
                font-size: 15px;
                line-height: 1;
                font-family: Microsoft YaHei;
            }
        }
    }

    &-show {
        padding: px2rem(90px) 0 px2rem(80px);
        @include pageBg;
        .page-common-title {
            &-en {
                font-size: 30px;
                font-family: PingFang SC, Microsoft YaHei;
            }
            &::after {
                width: 30px;
                height: 3px;
                background-color: #32659f;
            }
        }

        &-swiper {
            overflow: hidden;
            margin-top: 30px;
            position: relative;
            .swiper-slide-active {
                .business-show-img {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
        &-content {
            display: flex;
            height: 100%;
            @media screen and (max-width: 1024px) {
                flex-flow: column;
            }
            .info {
                height: 100%;
                background-color: #fff;
                background-repeat: no-repeat;
                background-position: bottom;
                background-size: contain;
                // flex: 1;
                width: 550px;
                box-sizing: border-box;
                padding: 100px 100px 0;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    padding: px2rem(50px) px2rem(40px) px2rem(60px);
                }
            }
        }

        &-item {
            height: 650px;
            cursor: pointer;
            @media screen and (max-width: 1024px) {
                height: auto;
            }
            .title {
                font-size: 28px;
                color: $fontColor333;
                // text-align: center;
                margin-bottom: 40px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(40px);
                    margin-bottom: px2rem(30px);
                }
            }
            .desc {
                font-size: 16px;
                color: $fontColor444;
                line-height: 40px;
                margin-bottom: 60px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(30px);
                    line-height: 2;
                    margin-bottom: px2rem(40px);
                    @include multiEllipsis(3);
                    max-height: px2rem(180px);
                    min-height: px2rem(180px);
                }
            }
            .more {
                font-size: 14px;
                color: $fontColor999;
                .iconfont {
                    font-size: 12px;
                }
            }
            &:hover {
                .business-show-img {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
        &-img {
            // @include zoomImg(1300px, 600px);
            // overflow: hidden;
            width: 750 / 1300 * 100%;
            height: 100%;
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                width: 100%;
                @include zoomImg(1300px, 600px);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: .4s;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(#000, .4);
                z-index: 5;
                transition: all .4s;
            }
        }
    }
    &-prev-btn,
    &-next-btn {
        position: absolute;
        color: #fff;
        font-size: 50px;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(40px);
            color: $fontColor333;
        }
    }
    &-prev-btn {
        left: 9%;
        @media screen and (max-width: 1024px) {
            left: 7%;
        }
    }
    &-next-btn {
        right: 9%;
        @media screen and (max-width: 1024px) {
            right: 7%;
        }
    }
}

.detail-wrapper {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    align-items: center;
    justify-content: center;
    display: none;
    .popup-info {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 1100 / 1903 * 100%;
        // height: 39.185vw;
        padding: 60px 0 60px 75px;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        @media screen and (max-width: 1024px) {
            // width: px2rem(710px);
            width: 93vw;
            padding: px2rem(60px) px2rem(20px) px2rem(50px) px2rem(40px);
            // height: px2rem(1150px);
            height: 80vh;
        }
        .detail-title {
            font-size: 26px;
            color: $fontColor333;
            position: relative;
            text-align: center;
            z-index: 1;
            margin-right: 75px;
            border-bottom: 1px solid #cedeeb;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                // padding-bottom: px2rem(50px);
                margin-right: px2rem(20px);
            }
            p {
                display: inline-block;
                position: relative;
                padding-bottom: 20px;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-1px);
                    background-color: #002f7b;
                }
            }
        }
        .popup-scroll {
            overflow: hidden;
            // height: 100%;
            position: relative;
            padding-right: 75px;
            // opacity: 0;
            transition: all .5s;
            height: 30.185vw;
            @media screen and (max-width: 1024px) {
                padding-right: 0;
                height: px2rem(780px);
            }
            .popup-content {
                font-size: 16px;
                color: $fontColor444;
                line-height: 39px;
                text-align: center;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(30px);
                    line-height: px2rem(54px);
                    padding-right: px2rem(20px);
                }
                a {
                    color: $fontColor444;
                }
                img {
                    max-width: 100%;
                    margin: 8px 0;
                }
                iframe {
                    max-width: 100%;
                }
                video {
                    max-width: 100%;
                }
                .content {
                    text-align: left;
                    padding-top: 23px;
                }
            }
            .bscroll-vertical-scrollbar {
                right: 25px !important;
                @media screen and (max-width: 1024px) {
                    right: 0 !important;
                    width: px2rem(8px) !important;
                }
                .bscroll-indicator {
                    background-color: $fontColor999 !important;
                    border: none !important;
                    border-radius: 2px !important;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #c3d5e5;
                }
            }
        }
        .popup-close {
            position: absolute;
            border: 2px solid $fontColor333;
            border-radius: 50%;
            padding: 7px;
            top: 23px;
            right: 23px;
            cursor: pointer;
            transition: all .5s;
            font-size: 0;
            line-height: 1;
            z-index: 10;
            @media screen and (max-width: 1024px) {
                top: 23px;
                right: 23px;
                padding: px2rem(14px);
            }
            &:hover {
                transform: rotate(180deg);
            }
            .iconfont {
                font-size: 12px;
                color: $fontColor333;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(20px);
                }
            }
        }
    }
}

.popup-show {
    display: flex;
    opacity: 1;
}