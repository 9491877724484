.page-right-bar {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99;
    transition: all .8s;
    // width: 114px;
    transform: translateX(0) translateY(-50%);
    width: 160px;
    opacity: 0;
    @media screen and (max-width: 1440px) {
        right: 0;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
    .close-right-bar {
        // display: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 26px 22px;
        cursor: pointer;
        box-sizing: border-box;
        background-color: rgba($fontColor333, .7);
        @media screen and (max-width: 1366px) {
            padding: 16px 20px 16px 16px;
            min-width: 140px;
        }
        .box {
            color: #fff;
            font-size: 14px;
            .iconfont {
                margin-left: 5px;
                font-size: 12px;
            }
        }
    }
    .content {
        display: flex;
        flex-flow: column;
        background-color: rgba(#333, .5);
        padding-right: 22px;
        padding-bottom: 20px;
        @media screen and (max-width: 1440px) {
            top: 25%;
            min-width: 160px;
            padding-right: 20px;
            padding-bottom: 20px;
            box-sizing: border-box;
        }
    }
    .item {
        cursor: pointer;
        display: inline-flex;
        justify-content: flex-end;

        &:not(:first-child) {
            margin-top: -1px;
        }

        .anchor-title {
            border-top: 1px solid #b2b4b6;
            border-bottom: 1px solid #b2b4b6;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            display: inline-flex;
            align-items: center;
            padding: 25px 0;
            transition: all .5s;
            // text-shadow: 0px 1px 2px rgba(255, 255, 255, .6);
            text-align: right;
            width: 114px;
            &::after {
                content: '';
                margin-left: 13px;
                width: 14px;
                height: 1px;
                background-color: #f6f6f6;
                transition: all .5s;
            }
        }

        &:hover {
            .anchor-title {
                color: $pageNavColor;
                padding: 25px 0 25px 15px;
                &::after {
                    background-color: $pageNavColor;
                    height: 2px;
                }
            }
        }
    }

    .item-active {
        .anchor-title {
            color: $pageNavColor;
            padding: 25px 0 25px 15px;
            &::after {
                background-color: $pageNavColor;
                height: 2px;
            }
        }
    }
}

.unfold-btn {
    font-size: 14px;
    color: #fff;
    position: fixed;
    right: 0;
    top: 50%;
    writing-mode: vertical-lr;
    -ms-writing-mode: lr-tb;
    background-color: rgba(#333, .7);
    padding: 30px 15px;
    cursor: pointer;
    transform: translateX(100%) translateY(-50%);
    transition: all .8s;
    z-index: 99;
    .iconfont {
        font-size: 12px;
        margin-bottom: 16px;
    }
    .w {
        letter-spacing: 3px;
    }
}

.fold {
    transform: translateX(calc(100% + 19px)) translateY(-50%);
    visibility: hidden;
}
.unfold {
    transform: translateX(0) translateY(-50%);
}