.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all .4s;
    @media screen and (max-width: 1024px) {
        background-image: linear-gradient(180deg, #145fab, transparent 90%);
    }
    .wp {
        @include wp(1440px);
    }
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px2rem(110px);
        @media screen and (max-width: 1024px) {
            height: px2rem(130px);
        }
    }
    &-logo {
        a {
            position: relative;
            display: inline-block;
        }
        img {
            transition: all .4s;
            @media screen and (max-width: 1024px) {
                width: px2rem(206px);
            }
            &:nth-of-type(1) {
                opacity: 1;
            }
            &:nth-of-type(2) {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    &-right {
        display: flex;
        align-items: center;
        height: 100%;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-mb-right {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
        }
    }
    &-mb-icon {
        .iconfont {
            font-size: px2rem(48px);
            color: #fff;
        }
    }
    &-nav {
        display: flex;
        align-items: center;
        height: 100%;
        li {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-right: 45px;
            }
            >a {
                display: block;
                color: #fff;
                font-size: 15px;
                line-height: 1;
                position: relative;
                padding: 10px 0;
                transition: all .4s;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    transition: all .4s;
                    background-color: #fff;
                    top: 100%;
                    left: 0;
                    opacity: 0;
                    transform: translateX(-50%);
                    visibility: hidden;
                }
                &:hover {
                    &::after {
                        transform: translateX(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &:hover {
                .hover-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .hover-menu {
                display: flex;
                flex-flow: column;
                padding: 10px 25px 25px;
                opacity: 0;
                position: absolute;
                background-color: rgba(#101316, .4);
                border-radius: 10px;
                transition: all .4s;
                visibility: hidden;
                left: 50%;
                transform: translateX(-50%) translateY(10px);
                top: 100%;
                a {
                    color: #fff;
                    font-size: 14px;
                    display: block;
                    text-align: center;
                    border-bottom: 1px solid rgba(#fff, .1);
                    width: 180px;
                    padding: 20px 0;
                    transition: all .3s;
                    &:hover {
                        color: $pageNavColor;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px;
                    border-color: transparent transparent rgba(#101316, .4);
                }
            }
        }
        .on {
            a {
                &::after {
                    transform: translateX(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .search-box {
        display: flex;
        box-sizing: border-box;
        position: relative;
        align-items: center;
        width: 3.8vw;
        z-index: 10;
        @media screen and (max-width: 1024px) {
            margin-left: px2rem(100px);
            margin-right: px2rem(30px);
        }
        @media screen and (max-width: 768px) {
            margin-left: px2rem(100px);
            margin-right: px2rem(20px)
        }
        &:hover {
            input {
                border: 1px solid $baseColor;
                width: 140px;
                background-color: #fff;
                &::placeholder {
                    color: #999;
                    font-size: 14px;
                }
            }
            .header-search {
                display: inline-block;
                .icon-sousuo {
                    color: #000;
                }
            }
            >.icon-sousuo {
                display: none;
                color: #000;
            }
        }
        .header-search {
            // display: none;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            background-color: transparent;
            cursor: pointer;
            @media screen and (max-width: 1024px) {
                pointer-events: none;
            }
            .icon-sousuo {
                font-size: 22px;
                color: #fff;
                transition: all .3s;
                position: static;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(40px);
                }
                @media screen and (max-width: 768px) {
                    font-size: px2rem(40px);
                }
            }
        }
        input {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all .3s;
            border: 1px solid transparent;
            padding-right: 30px;
            padding-left: 20px;
            border-radius: 20px;
            height: 40px;
            width: 0;
            background-color: transparent;
            @media screen and (max-width: 768px) {
                height: px2rem(50px);
                padding: 0 px2rem(20px);
                right: 0;
            }
        }
    }
    .search-box-active {
        .header-search {
            pointer-events: auto;
        }
    }
}

.header-scroll {
    background-color: #fff;
    .header-nav {
        li {
            >a {
                color: $fontColor333;
                &::after {
                    background-color: $pageNavColor;
                }
            }
        }
    }
    .header-logo {
        a {
            img {
                &:nth-of-type(1) {
                    opacity: 0;
                }
                &:nth-of-type(2) {
                    opacity: 1;
                }
            }
        }
    }
    .search-box {
        .header-search {
            .icon-sousuo {
                color: $fontColor333;
            }
        }
    }
}

.nav-mobile {
    position: absolute;
    width: px2rem(530px);
    top: px2rem(110px);
    right: 0;
    bottom: 0;
    z-index: 103;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 9902;
        box-sizing: border-box;
        overflow: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .4);
            z-index: 102;
        }
    }
    &-top {
        width: 100%;
        height: px2rem(110px);
        line-height: px2rem(110px);
        box-sizing: border-box;
        padding: 0 px2rem(50px);
        background-color: #393939;
        font-size: px2rem(30px);
        color: #fff;
        z-index: 103;
        position: absolute;
        top: 0;
        left: 0;
    }
    &-wp {
        position: relative;
    }
    &-title {
        position: relative;
        margin: 0 px2rem(50px);
        border-bottom: 1px solid #ededed;
        padding: px2rem(15px) 0;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        > a {
            position: relative;
            display: block;
            line-height: px2rem(84px);
            font-size: px2rem(28px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
            flex: 1;
        }
    }
    &-search {
        display: flex;
        padding: 0 px2rem(50px);
        width: 100%;
        box-sizing: border-box;
        margin: px2rem(30px) 0 ;
        .input-box {
            height: px2rem(50px);
            display: flex;
            width: 100%;
            padding: px2rem(20px) px2rem(30px);
            border-radius: px2rem(50px);
            border: 1px solid #eee;
            input {
                flex: 1;
                height: 100%;
                border: none;
            }
            button {
                background-color: transparent;
                border: none;
                font-size: px2rem(40px);
            }
        }
    }
    &-child-btn {
        // position: absolute;
        // width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(30px);
        font-weight: bold;
        z-index: 1;
        color: #848484;
        padding-left: px2rem(50px);
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            // position: absolute;
            width: px2rem(40px);
            display: flex;
            align-items: center;
            height: 100%;
            top: 0;
            right: 0;
            color: #888;
            line-height: px2rem(84px);
            text-align: center;
            font-size: px2rem(30px);
            //transform: rotate(180deg);
            transition: transform .2s ease;
            &:before {
                content: '\e63e';
                font-family: 'iconfont';
            }
        }
        &.open {
            i {
                transform: rotate(90deg);
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        padding: 0 px2rem(40px);
        display: none;
        li {
            border-bottom: 1px solid #ededed;
            padding-left: px2rem(30px);
            position: relative;
            height: px2rem(100px);
            overflow: hidden;
            a {
                display: block;
                line-height: px2rem(84px);
                font-size: px2rem(28px);
                height: px2rem(84px);
                color: #333;
                overflow: hidden;
            }
        }
    }
}