.page {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1024px) {
        position: static;
        height: auto;
    }
}

.index-main-swiper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    &>.swiper-wrapper>.swiper-slide {
        overflow: hidden;
    }
    @media screen and (max-width: 1024px) {
        height: auto;
        overflow: auto;
        &>.swiper-wrapper {
            flex-flow: column;
        }
        &>.swiper-wrapper>.swiper-slide {
            height: auto;
        }
    }
}

.main-pagination {
    position: absolute;
    z-index: 99;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    .swiper-pagination-bullet {
        width: 20px;
        height: 5px;
        background-color: #f6f6f6;
        border-radius: 0;
        opacity: 1;
        margin: 12px 0 !important;
        transition: all .4s;
        outline: none;
    }
    .swiper-pagination-bullet-active {
        width: 40px;
        background-color: #e50031;
    }
}

// banner
.index-banner {
    &-swiper {
        overflow: hidden;
    }
    &-img {
        display: block;
        position: relative;
        .img-box {
            padding-bottom: 100vh;
            position: relative;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include mbImg;
            }
        }
        .banner-word {
            color: #fff;
            font-weight: bold;
            position: absolute;
            z-index: 10;
            top: 350px;
            left: px2vw(374);
            font-size: px2vw(95);
            @media screen and (max-width: 1024px) {
                font-size: px2rem(95px);
                max-width: 100%;
                top: px2rem(360px);
            }
        }
    }
    &-pagination {
        position: absolute;
        bottom: 30px;
        z-index: 66;
        text-align: center;
        .swiper-pagination-bullet {
            margin: 0 5px;
            opacity: 1;
            width: 8px;
            height: 8px;
            background-color: #efefef;
        }
        .swiper-pagination-bullet-active {
            background-color: $baseColor;
        }
    }
}

// 关于我们
.index-about {
    &-wrapper {
        position: relative;
        @include bg;
        height: 100%;
        @media screen and (max-width: 1024px) {
            @include mbBg
        }
    }
    &-content {
        // width: 850 / 1920 * 100%;
        width: 850px;
        padding-top: 175 / 1920 * 100%;
        padding-left: 705 / 1920 * 100%;
        @media screen and (max-width: 1440px) {
            padding-left: 600 / 1920 * 100%;
            padding-top: 150 / 1920 * 100%;
        }
        @media screen and (max-width: 1024px) {
            width: 100%;
            box-sizing: border-box;
            padding: px2rem(90px) px2rem(35px) 0;
        }
    }
    &-desc {
        font-size: 15px;
        color: #fff;
        line-height: 36px;
        margin-top: 40px;
        @media screen and (max-width: 1440px) {
            margin-top: 30px;
        }
        @media screen and (max-width: 1366px) {
            margin-top: 27px;
            line-height: 30px;
        }
        @media screen and (max-width: 1024px) {
            font-size: px2rem(26px);
            line-height: px2rem(54px);
            @include multiEllipsis(6);
        }
    }
    &-btn {
        display: flex;
        align-items: center;
        margin-top: 43px;
        @media screen and (max-width: 1440px) {
            margin-top: 25px;
        }
        @media screen and (max-width: 1024px) {
            flex-flow: wrap;
        }
    }
    &-btn-item {
        width: 25%;
        box-sizing: border-box;
        padding-right: 17px;
        font-size: 0;
        @media screen and (max-width: 1024px) {
            width: 50%;
            padding-right: 0;
            margin-bottom: px2rem(21px);
            &:nth-child(odd) {
                padding-right: px2rem(11px);
            }
            &:nth-child(even) {
                padding-left: px2rem(11px);
            }
        }
        a,
        span {
            display: block;
            // width: 200 / 850 * 100%;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid rgba(#fff, .4);
            font-size: 22px;
            color: #fff;
            text-align: center;
            padding: 13px 0;
            line-height: 1;
            overflow: hidden;
            position: relative;
            background-color: rgba(#0c204e, .1);
            @media screen and (max-width: 1024px) {
                font-size: px2rem(30px);
                padding: px2rem(22px) 0;
            }
            p {
                position: relative;
                z-index: 10;
            }
            .en {
                font-size: 12px;
                font-family: Arial;
                opacity: .5;
                margin-top: 8px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(20px);
                }
            }
        }
    }
    &-counter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(#002a4d, .3);
        .wp {
            @include wp(1440px);
        }
    }
    &-counter-content {
        display: flex;
        height: 13vw;
        align-items: center;
        @media screen and (max-width: 1366px) {
            height: 10vw;
        }
        @media screen and (max-width: 1024px) {
            height: px2rem(250px);
        }
    }
    &-counter-item {
        font-size: 15px;
        color: #fff;
        text-align: center;
        flex: 1;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(22px);
        }
        .num {
            font-family: 'LeagueGothic';
            font-size: 70px;
            color: #fff;
            line-height: 1.8;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1366px) {
                font-size: 48px;
                line-height: 1.5;
            }
            @media screen and (max-width: 1024px) {
                font-size: px2rem(60px);
                line-height: 1.8;
            }
        }
        &:nth-child(3),
        &:nth-child(4) {
            .num::after {
                content: '+';
                color: #fff;
                font-size: 24px;
                margin-left: 10px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(30px);
                    margin-left: px2rem(5px);
                }
            }
        }
    }
}

// 业务布局
.index-business {
    &-wrapper {
        @include bg;
        position: relative;
        height: 100%;
        @media screen and (max-width: 1024px) {
            @include mbBg;
        }
    }
    &-content {
        padding-top: 180 / 1920 * 100%;
        max-width: 1160px;
        margin: 0 auto;
        position: relative;
        z-index: 10;
        @media screen and (max-width: 1160px) {
            padding: 0 px2rem(30px);
        }
        @media screen and (max-width: 1024px) {
            padding-top: px2rem(90px);
        }
    }
    &-box {
        display: flex;
        flex-flow: wrap;
        margin-top: 50px;
        @media screen and (max-width: 1024px) {
            margin-top: px2rem(60px);
        }
    }
    &-btn {
        width: 50%;
        box-sizing: border-box;
        @media screen and (max-width: 1024px) {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: px2rem(20px);
            }
        }
        @media screen and (min-width: 1024px) {
            &:nth-child(odd) {
                padding-right: 20px;
            }
            &:nth-child(even) {
                padding-left: 20px;
            }
            &:nth-child(-n+2) {
                margin-bottom: 38px;
                @media screen and (max-width: 1440px) {
                    margin-bottom: 20px;
                }
            }
        }
        a {
            display: block;
            box-sizing: border-box;
            border: 1px solid rgba(#fff, .4);
            background-color: rgba(#000, .1);
            font-size: 22px;
            color: #fff;
            padding: 30px 35px 60px;
            transition: all .4s;
            @media screen and (max-width: 1440px) {
                padding: 30px 20px 40px;
            }
            @media screen and (max-width: 1366px) {
                padding: 20px;
            }
            @media screen and (max-width: 1024px) {
                font-size: px2rem(28px);
                padding: px2rem(30px) px2rem(35px) px2rem(30px);
            }
            &:hover {
                background-color: #0f5398;
            }
            .en { 
                font-family: Arial;
                font-size: 12px;
                opacity: .7;
                margin-top: 10px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(20px);
                    margin-top: 0;
                }
            }
            .btn-arrow {
                border-radius: 50%;
                width: px2rem(30px);
                height: px2rem(30px);
                border: px2rem(2px) solid #cffcff;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: .6;
                margin-top: 25px;
                box-sizing: border-box;
                @media screen and (max-width: 1366px) {
                    margin-top: 15px;
                }
                @media screen and (max-width: 1024px) {
                    margin-top: px2rem(25px);
                }
                .iconfont {
                    color: #cffcff;
                    font-size: 14px;
                    @media screen and (max-width: 1024px) {
                        font-size: px2rem(16px);
                    }
                }
            }
        }
    }
    &-hover-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        padding-bottom: 100vh;
        @media screen and (max-width: 1024px) {
            display: none;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
            transition: all .4s;
            transform: scale(.98);
        }
        .img-show {
            opacity: 1;
            transform: scale(1);
        }
    }
    &-mb-swiper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @media screen and (min-width: 1024px) {
            display: none;
        }
        .img-box {
            @include zoomImg(750px, 1200px);
        }
    }
}

// 目标
.index-target {
    &-wrapper {
        @include bg;
        height: 100%;
        @media screen and (max-width: 1024px) {
            @include mbBg;
        }
    }
    &-content {
        width: 750px;
        margin: 0 auto;
        padding-top: 190 / 1920 * 100%;
        @media screen and (max-width: 1024px) {
            width: 100%;
            box-sizing: border-box;
            padding: px2rem(100px) px2rem(30px) 0;
        }
    }
    &-title {
        text-align: center;
        font-size: px2rem(46px);
        color: #fff;
        .s-title {
            margin-top: 45px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            color: rgba(#fff, .8);
            @media screen and (max-width: 1024px) {
                font-size: px2rem(30px);
                font-weight: bold;
            }
        }
    }
    &-desc {
        font-size: 15px;
        font-family: Source Han Sans CN;
        color: rgba(#fff, .8);
        line-height: 36px;
        text-align: center;
        margin-top: 15px;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(26px);
            line-height: px2rem(54px);
        }
    }
    &-counter {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(#fff, .15);
        margin-top: 40px;
        padding-top: 58px;
        @media screen and (max-width: 1366px) {
            margin-top: 20px;
            padding-top: 30px;
        }
    }
    &-counter-item {
        font-size: 15px;
        color: #fff;
        text-align: center;
        flex: 1;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(22px);
        }
        .num {
            font-family: 'LeagueGothic';
            font-size: px2rem(70px);
            color: #fff;
            line-height: 1.8;
            user-select: none;
        }
    }
}

// 发展历程
.index-history {
    &-wrapper {
        @include bg;
        height: 100%;
        @media screen and (max-width: 1024px) {
            @include mbBg;
        }
    }
    &-content {
        padding-top: 180 / 1920 * 100%;
        position: relative;
        @media screen and (max-width: 1024px) {
            padding: px2rem(120px) 0 0;
        }
        &::after {
            content: '';
            position: absolute;
            top: calc(100% - 5px);
            height: 1px;
            width: 100%;
            background-color: #f6f6f6;
            opacity: .2;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
    .swiper-slide {
        height: auto;
    }
    &-info {
        text-align: center;
        position: relative;
        height: 100%;
        &::after {
            content: '';
            position: absolute;
            top: calc(100% + 40px);
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 10px;
            background-color: #fff;
            border-radius: 50%;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
    &-year {
        font-size: 30px;
        color: #fff;
        font-weight: bold;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(36px);
            line-height: 1.6;
        }
    }
    &-box {
        position: relative;
    }
    &-swiper {
        width: 1400 / 1920 * 100%;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 180 / 1920 * 100%;
        padding-bottom: 50px;
        @media screen and (max-width: 1366px) {
            margin-top: 60 / 1366 * 100%;
        }
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding-bottom: 0;
            margin-top: px2rem(300px);
            padding: 0 px2rem(60px) 0;
            box-sizing: border-box;
        }
    }
    &-desc {
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        @media screen and (max-width: 1366px) {
            line-height: 24px;
        }
        @media screen and (max-width: 1024px) {
            font-size: px2rem(24px);
            line-height: px2rem(40px);
        }
    }
    &-prev-btn,
    &-next-btn {
        position: absolute;
        z-index: 10;
        top: 30%;
        color: #fff;
        font-size: 22px;
        cursor: pointer;
        outline: none;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(30px);
        }
    }
    &-prev-btn {
        left: 200 / 1920 * 100%;
        @media screen and (max-width: 1024px) {
            left: px2rem(15px);
        }
    }
    &-next-btn {
        right: 200 / 1920 * 100%;
        @media screen and (max-width: 1024px) {
            right: px2rem(15px);
        }
    }
}

// footer
.footer {
    height: auto;
    background-color: $baseColor;
    .wp {
        @include wp(1440px);
    }
    &-wrapper {
        // height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 0;
        @media screen and (max-width: 1366px) {
            height: 80px;
            padding: 30px 0;
        }
        @media screen and (max-width: 1024px) {
            flex-flow: column;
            justify-content: center;
            padding: px2rem(45px) 0;
        }
    }
    &-item {
        color: rgba(#fff, .7);
        font-size: 14px;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(24px);
            text-align: center;
            span {
                display: inline-block;
                margin-bottom: px2rem(10px);
            }
            &:first-child {
                margin-bottom: px2rem(10px);
            }
        }
        a {
            color: rgba(#fff, .7);
            &:hover {
                color: #fff;
            }
        }
    }
}