.recru {
    padding: px2rem(90px) 0 px2rem(90px);
    @include pageBg;
    .wp {
        @include wp(1400px);
    }
    &-list {
        margin-top: 45px;
        margin-bottom: 50px;
        li:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    // &-list-item {
    //     @include anime(fadeInUp);
    // }

    &-list-head {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 16px 26px 16px 33px;
        background-color: #f7f7f7;
        line-height: 1;
        transition: all .3s;
        @media screen and (max-width: 1024px) {
            padding: 16px px2rem(20px);
        }
        @media (any-hover: hover) {
            &:hover {
                background-color: $pageNavColor;
                .recru-list-box {
                    color: #fff;
                }
                .recru-list-arrow {
                    background-color: #fff;
                    color: $pageNavColor;
                }
            }
        }
    }
    &-list-head-active {
        background-color: $pageNavColor;
        .recru-list-box {
            color: #fff;
        }
        .recru-list-arrow {
            background-color: #fff;
            color: $pageNavColor;
            transform: rotate(-180deg);
        }
    }

    &-list-box {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 16px;
        color: $fontColor666;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(26px);
        }
        span {
            display: inline-block;
            // flex: 1;
            transition: all .3s;
            &:nth-of-type(1) {
                width: 448 / 1313 * 100%;
                @media screen and (max-width: 1024px) {
                    flex: 1.1;
                }
            }
            &:nth-of-type(2) {
                width: 189 / 1313 * 100%;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
            &:nth-of-type(3) {
                width: 218 / 1313 * 100%;
                @media screen and (max-width: 1024px) {
                    flex: 1.1;
                }
            }
            &:nth-of-type(4) {
                width: 186 / 1313 * 100%;
                @media screen and (max-width: 1024px) {
                    flex: .9;
                }
            }
            &:nth-of-type(5) {
                width: 270 / 1313 * 100%;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }

    &-list-arrow {
        color: $fontColor666;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e4e4e4;
        font-size: 16px;
        // padding: 0 px2rem(20px);
        transition: all .3s;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(24px);
            width: px2rem(40px);
            height: px2rem(40px);
        }
    }

    &-list-title {
        font-size: 18px;
        color: #000;
        line-height: 1;
        padding-left: 15px;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(32px);
            padding-left: px2rem(20px);
        }
    }

    &-list-content {
        padding: 30px 30px 60px;
        display: none;
        @media screen and (max-width: 1024px) {
            padding: px2rem(30px) px2rem(30px) px2rem(60px);
        }
        .recru-list-head-mb {
            display: none;
            @media screen and (max-width: 1024px) {
                display: block;
                font-size: px2rem(30px);
                line-height: 2;
                padding-bottom: px2rem(40px);
                margin-bottom: px2rem(40px);
                border-bottom: px2rem(1px) dashed rgba(#000, .3);
            }
        }
        .content-box {
            display: flex;
            flex-flow: column;
            // @media screen and (max-width: 1024px) {
            //     flex-flow: column;
            // }
            .content-item {
                font-size: 18px;
                color: #000;
                line-height: 35px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(32px);
                    line-height: 2;
                }
                &:not(:last-child) {
                    margin-bottom: 40px;
                }
                .content {
                    font-size: 16px;
                    color: $fontColor444;
                    margin-top: 10px;
                    @media screen and (max-width: 1024px) {
                        font-size: px2rem(28px);
                    }
                }
            }
        }
        .contact {
            font-size: 16px;
            line-height: 35px;
            color: #000;
            font-weight: bold;
            margin-top: 20px;
        }
        .recru-btn {
            margin-top: px2rem(50px);
            a {
                display: inline-block;
                font-size: 14px;
                color: #fff;
                line-height: 1;
                background-color: $pageNavColor;
                padding: 13px 42px;
                transition: all .4s;
                &:hover {
                    background-color: #b4042a;
                }
                @media screen and (max-width: 1024px) {
                    padding: px2rem(24px) px2rem(60px);
                    font-size: px2rem(30px);
                }
            }
        }
    }
}