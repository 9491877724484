


.swiper-button-disabled {
    opacity: .5;
    cursor: not-allowed !important;
    transition: all .4s;
}


@mixin pageBg {
    background-repeat: no-repeat, no-repeat;
    background-size: auto, 100% 100%;
    background-position: right bottom, top;
    @media screen and (max-width: 1024px) {
        background-size: 50%, 100% 100%;
    }
}


.h100 {
    height: 100%;
    @media screen and (max-width: 1024px) {
        height: px2rem(1200px);
    }
}

.wow {
    visibility: hidden;
    @include anime;
}

.common-title {
    color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    line-height: 1;
    &-en {
        font-size: px2rem(40px);
        margin-bottom: px2rem(10px);
    }
    &-cn {
        font-size: px2rem(36px);
    }
}
.common-title-left {
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
        align-items: center;
    }
}

.page-common-title {
    color: $fontColor333;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    &-cn {
        font-size: 36px;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(50px);
        }
    }
    &-en {
        font-size: 40px;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(52px);
        }
    }
    &::after {
        content: '';
        width: 50px;
        height: 5px;
        background-color: $baseColor;
        margin-top: 16px;
        @media screen and (max-width: 1024px) {
            margin-top: px2rem(25px);
            width: px2rem(80px);
        }
    }
}

.page-banner {
    .wp {
        @include wp(1440px);
    }
    &-img {
        @include zoomImg(1903px, 590px);
        @media screen and (max-width: 1024px) {
            @include zoomImg(750px, 450px);
        }
        img {
            @include mbImg;
        }
    }
    &-nav {
        height: 75px;
        background-color: #fff;
        display: flex;
        align-items: center;
        overflow-y: auto;
        @media screen and (max-width: 1024px) {
            justify-content: flex-start;
            height: px2rem(110px);
            padding: 0 px2rem(30px);
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    &-nav-item {
        line-height: 1;
        font-size: 16px;
        color: $fontColor666;
        display: flex;
        align-items: center;
        height: 100%;
        transition: all .3s;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 5px;
        border-bottom: 3px solid transparent;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(28px);
            padding: px2rem(18px) px2rem(40px);
        }
        &:not(:last-child) {
            margin-right: px2rem(40px);
        }
        &:hover {
            color: $pageNavColor;
            border-bottom-color: $pageNavColor;
        }
    }
    .nav-item-active {
        color: $pageNavColor;
        border-bottom-color: $pageNavColor;
    }
}