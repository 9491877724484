.brand {
    .wp {
        @include wp(1200px);
    }

    &-wrapper {
        padding-top: px2rem(86px);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top;
    }

    &-content {
        &-box {
            margin-top: px2rem(73px);
            font-size: 16px;
            color: #333;
            line-height: 40px;
            padding-bottom: px2rem(100px);
        }

        &-item {
            .title {
                font-size: 20px;
                color: #fff;
                font-weight: bold;
                line-height: 1;
                padding: 15px 33px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(34px);
                    padding: px2rem(25px) px2rem(40px);
                }
            }

            .desc {
                font-size: 16px;
                color: $fontColor333;
                line-height: 40px;
                padding: 18px 33px 65px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(30px);
                    line-height: 2;
                    padding: px2rem(20px) px2rem(30px) px2rem(80px);
                }
            }

            &:nth-child(1) {
                .title {
                    background-image: linear-gradient(87deg, #115fad 0%, #a4afd0 48%, #b8c1db 73%, #ccd3e5 100%), linear-gradient(#3972bf, #3972bf);
                }
            }

            &:nth-child(2) {
                .title {
                    background-image: linear-gradient(87deg, #d93636 0%, #df6d55 27%, #e4a373 53%, #f2e4ca 100%), linear-gradient(#3972bf, #3972bf);
                }
            }

            &:nth-child(3) {
                .title {
                    background-image: linear-gradient(87deg, #e0062d 0%, #e24750 27%, #e48773 53%, #e5c0b2 100%), linear-gradient(#3972bf, #3972bf);
                }
            }
        }
    }

    &-swiper-wrapper {
        padding: px2rem(90px) 0 px2rem(100px);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: right bottom;
        background-color: #e1e7f2;
        @media screen and (max-width: 1024px) {
            background-size: 50%;
        }
        .wp {
            @include wp(1300px);
        }
        .brand-swiper {
            position: relative;
            &-box {
                width: 1200 / 1300 * 100%;
                overflow: hidden;
                margin: 0 auto;
                // @media screen and (max-width: 1024px) {
                //     width: 100%;
                // }
            }
            &-item {
                display: block;
                &:hover {
                    .brand-swiper-img {
                        img {
                            transform: scale(1.05)
                        }
                    }
                    .brand-swiper-title {
                        color: $pageNavColor;
                    }
                }
            }
            &-img {
                overflow: hidden;
                @include zoomImg(580px, 380px);
            }
            &-title {
                font-size: 16px;
                color: $fontColor333;
                line-height: 40px;
                text-align: center;
                transition: all .4s;
                margin-top: 14px;
                padding: 0 20px;
                @include textEllipsis;
            }

            &-prev-btn,
            &-next-btn {
                position: absolute;
                top: 40%;
                // transform: translateY(-50%);
                z-index: 10;
                cursor: pointer;
                font-size: 26px;
                color: #000;
                transition: all .4s;
                &:not(.swiper-button-disabled):hover {
                    color: $pageNavColor;
                }
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(40px);
                    // color: #fff;
                }
            }
            &-prev-btn {
                left: 0;
                @media screen and (max-width: 1024px) {
                    left: px2rem(-18px);
                }
            }
            &-next-btn {
                right: 0;
                @media screen and (max-width: 1024px) {
                    right: px2rem(-18px);
                }
            }
        }
    }
}