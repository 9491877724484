.contact {
    // @include pageBg;
    padding: px2rem(80px) 0;

    .wp {
        @include wp(1400px);
    }

    // @media screen and (max-width: 1024px) {
    //     display: flex;
    //     flex-flow: column-reverse;
    // }

    .map {
        // height: 880px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        // @media screen and (max-width: 1024px) {
        //     height: px2rem(650px);
        // }
    }

    &-content {
        // position: absolute;
        // right: 50%;
        // top: 50%;
        // transform: translateY(-50%);
        width: 512px;
        background-color: #fff;
        padding: 60px 65px 53px;
        box-shadow: 0px 2px 10px 0px rgba(163, 163, 163, .75);
        // margin-right: 190 / 1903 * 100%;
        margin-right: 30px;

        @media screen and (max-width: 1024px) {
            margin-bottom: 50px;
        }


        @media screen and (max-width: 1440px) {
            padding: 60px 40px 53px;
        }

        @media screen and (max-width: 1024px) {
            position: static;
            box-sizing: border-box;
            padding: px2rem(40px) px2rem(45px) px2rem(50px);
            width: 100%;
            transform: translateY(0) translateX(0);
            margin-right: 0;
        }

        &-title {
            display: flex;
            flex-flow: column;

            .cn {
                font-size: 36px;
                color: $fontColor333;

                @media screen and (max-width: 1024px) {
                    font-size: px2rem(44px);
                }
            }

            .en {
                font-size: 40px;
                color: $fontColor333;

                @media screen and (max-width: 1024px) {
                    font-size: px2rem(42px);
                }
            }

            &::after {
                content: '';
                width: 50px;
                height: 5px;
                background-color: $baseColor;
                margin-top: 14px;

                @media screen and (max-width: 1024px) {
                    width: px2rem(80px);
                    height: px2rem(8px);
                    margin-top: px2rem(15px);
                }
            }
        }

        .compony-name {
            font-size: 20px;
            color: #fff;
            font-weight: bold;
            padding: 12px 30px;
            background-image: linear-gradient(87deg, #115fad 0%, #7796c6 50%, #a4afd0 72%, #b8c1db 86%, #ccd3e5 100%), linear-gradient(#3972bf, #3972bf);
            margin-top: 30px;
            margin-bottom: 26px;

            @media screen and (max-width: 1024px) {
                font-size: px2rem(28px);
                padding: px2rem(18px) px2rem(26px);
                margin: px2rem(30px) 0 px2rem(25px);
            }
        }

        .address {
            font-size: 16px;
            color: $fontColor333;
            line-height: 30px;

            @media screen and (max-width: 1024px) {
                line-height: 1.8;
                font-size: px2rem(26px);
            }
        }

        .qrcode {
            margin-top: 20px;

            @media screen and (max-width: 1024px) {
                margin-top: px2rem(25px);
            }
        }
    }

    .map-box {
        width: 100%;

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }
}