@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-20%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes logoInLeft {
    from {
        opacity: 0;
        transform: translate3d(-40%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(20%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate(0, 40px);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0);
    }
}

@keyframes fadeInUpMb {
    from {
        opacity: 0;
        transform: translate3d(-50%, 40px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

@keyframes flipInX {
    from {
        transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(1000px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(1000px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(1000px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(1000px);
        opacity: 1;
    }
}

.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
}

@keyframes bounce {

    from,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        transform: translate3d(0, -30px, 0) scaleY(1.1);
    }

    70% {
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        transform: translate3d(0, -15px, 0) scaleY(1.05);
    }

    80% {
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        transform: translate3d(0, 0, 0) scaleY(.95);
    }

    90% {
        transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
}

.bounce {
    animation-name: bounce;
    transform-origin: center bottom;
}

@keyframes trackingInExpand {
    0% {
        letter-spacing: -.5em;
        opacity: 0;
    }

    40% {
        opacity: .6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flipInHorTop {
    0% {
        transform: rotateX(-80deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes scaleInHorLeft {
    0% {
        transform: scaleX(0);
        transform-origin: 0% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

@keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(.9, .9, .9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(.97, .97, .97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.bounceIn {
    animation-duration: calc(var(--animate-duration) * .75);
    animation-name: bounceIn;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    100% {
        opacity: 1;
    }
}

@keyframes heartBeat {
    0% {
        transform: scale(1) rotate(-45deg);
    }

    14% {
        transform: scale(1.3) rotate(-45deg);
    }

    28% {
        transform: scale(1) rotate(-45deg);
    }

    42% {
        transform: scale(1.3) rotate(-45deg);
    }

    70% {
        transform: scale(1) rotate(-45deg);
    }
}