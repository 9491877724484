.about-title {
    color: #fff;
    display: flex;
    flex-flow: column;
    text-align: center;

    &-cn {
        font-size: px2vw(36);
        margin-bottom: px2vw(13);
        @media screen and (max-width: 1024px) {
            font-size: px2rem(48px);
        }
    }

    &-en {
        font-size: px2vw(40);
        line-height: px2vw(36);
        margin-bottom: px2vw(26);
        @media screen and (max-width: 1024px) {
            font-size: px2rem(52px);
            line-height: 1.5;
        }
    }

    &::after {
        content: '';
        width: 50px;
        height: 5px;
        background-color: #fff;
        margin: 0 auto;
    }
}

.about-us {
    &-wrapper {
        position: relative;
        @include bg;
        height: 960px;

        @media screen and (max-width: 1024px) {
            @include mbBg;
            height: px2rem(1200px);
        }
    }

    .about-title {
        text-align: left;

        &::after {
            margin: 0;
        }
    }

    &-content {
        // width: 850 / 1920 * 100%;
        width: 850px;
        padding-top: px2rem(240px);
        padding-left: 705 / 1903 * 100%;

        @media screen and (max-width: 1440px) {
            padding-left: 600 / 1903 * 100%;
        }

        @media screen and (max-width: 1366px) {
            padding-left: 400 / 1903 * 100%;
        }

        @media screen and (max-width: 1024px) {
            width: 100%;
            box-sizing: border-box;
            padding: px2rem(90px) px2rem(35px) 0;
        }
    }

    &-desc {
        font-size: 15px;
        color: #fff;
        line-height: 36px;
        margin-top: 30px;

        @media screen and (max-width: 1366px) {
            margin-top: 27px;
            line-height: 30px;
        }

        @media screen and (max-width: 1024px) {
            font-size: px2rem(26px);
            line-height: px2rem(54px);
            @include multiEllipsis(6);
        }
    }

    &-btn {
        display: flex;
        align-items: center;
        margin-top: 43px;

        @media screen and (max-width: 1366px) {
            margin-top: 25px;
        }

        @media screen and (max-width: 1024px) {
            flex-flow: wrap;
        }
    }

    &-btn-item {
        width: 25%;
        box-sizing: border-box;
        padding-right: 17px;
        font-size: 0;

        @media screen and (max-width: 1024px) {
            width: 50%;
            padding-right: 0;
            margin-bottom: px2rem(21px);
            &:nth-child(odd) {
                padding-right: px2rem(11px);
            }

            &:nth-child(even) {
                padding-left: px2rem(11px);
            }
        }

        a,
        span {
            display: block;
            // width: 200 / 850 * 100%;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid rgba(#fff, .4);
            font-size: 22px;
            color: #fff;
            text-align: center;
            padding: 13px 0;
            line-height: 1;
            overflow: hidden;
            position: relative;
            background-color: rgba(#0c204e, .1);

            @media screen and (max-width: 1024px) {
                font-size: px2rem(30px);
                padding: px2rem(22px) 0;
            }

            p {
                position: relative;
                z-index: 10;
            }

            // &::after {
            //     content: '';
            //     position: absolute;
            //     z-index: 0;
            //     background-color: $baseColor;
            //     width: 100%;
            //     height: 100%;
            //     top: 50%;
            //     left: 50%;
            //     transition: all .5s;
            //     transform: translate(-50%, -50%) scaleX(0);
            // }

            // &:hover {
            //     &::after {
            //         transform: translate(-50%, -50%) scaleX(1);
            //     }
            // }

            .en {
                font-size: 12px;
                font-family: Arial;
                opacity: .5;
                margin-top: 8px;

                @media screen and (max-width: 1024px) {
                    font-size: px2rem(20px);
                }
            }
        }
    }

    &-counter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(#002a4d, .3);
        @include anime;
        .wp {
            @include wp(1400px);
        }
    }

    &-counter-content {
        display: flex;
        height: 250px;
        align-items: center;

        // @media screen and (max-width: 1366px) {
        //     height: 10vw;
        // }
        @media screen and (max-width: 1024px) {
            height: px2rem(220px);
        }
    }

    &-counter-item {
        font-size: 15px;
        color: #fff;
        text-align: center;
        flex: 1;

        @media screen and (max-width: 1024px) {
            font-size: px2rem(22px);
        }

        .num {
            font-family: 'LeagueGothic';
            font-size: 70px;
            color: #fff;
            line-height: 1.8;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 1366px) {
                font-size: 48px;
                line-height: 1.5;
            }

            @media screen and (max-width: 1024px) {
                font-size: px2rem(60px);
                line-height: 1.8;
            }
        }

        &:nth-child(3),
        &:nth-child(4) {
            .num::after {
                content: '+';
                color: #fff;
                font-size: 24px;
                margin-left: 10px;

                @media screen and (max-width: 1024px) {
                    font-size: px2rem(30px);
                    margin-left: px2rem(5px);
                }
            }
        }
    }
}

.about-culture {
    height: 960px;
    @include bg;
    display: flex;
    @media screen and (max-width: 1024px) {
        flex-flow: column;
        height: auto;
        padding: px2rem(100px) 0 px2rem(80px);
    }
    @media screen and (min-width: 1024px) {
        .about-title {
            text-align: left;
            &::after {
                margin: 0;
            }
        }
    }

    &-item {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        padding-bottom: 150px;
        transition: all .4s;
        @media screen and (min-width: 1024px) {
            &:not(:first-child) {
                width: px2vw(350);
                flex-shrink: 0;
                border-left: 1px solid rgba(#fff, .2);
                &:hover {
                    background-color: rgba(#000, .2);
                }
            }
            &:first-child {
                flex: 1;
                padding-right: px2vw(155);
                .about-title {
                    width: px2vw(250);
                }
            }
        }
        @media screen and (max-width: 1024px) {
            padding-bottom: px2rem(100px);
            border-bottom: 1px solid rgba(#fff, .2);
            margin-bottom: px2rem(60px);
        }

        .title {
            font-size: 36px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(60px);
            }
        }

        .s-title {
            font-size: 22px;
            font-family: PingFang SC;
            margin-top: 12px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(40px);
            }
        }

        .icon {
            margin-bottom: px2rem(50px);
            @media screen and (max-width: 1024px) {
                margin-bottom: px2rem(40px);
            }
        }
    }
}

.about-partner {
    @include bg;
    // padding-bottom: 960 / 1903 * 100%;
    height: px2vw(960);
    position: relative;
    background-position: center;
    @media screen and (max-width: 1024px) {
        height: px2rem(938px);
    }
    .wp {
        @media screen and (max-width: 1800px) {
            padding: 0 140px;
        }
        @include wp(1464px, 336px);

    }

    &-wrapper {
        // position: absolute;
        width: 100%;
        top: 114 / 960 * 100%;
        padding-top: 114 / 1903 * 100%;
        // padding: 0 219.5px;
        box-sizing: border-box;
        @media screen and (max-width: 1024px) {
            padding: px2rem(100px) 0 px2rem(80px);
        }
    }

    &-swiper {
        overflow: hidden;
        margin-top: px2vw(57);
        @media screen and (max-width: 1024px) {
            margin-top: px2rem(60px);
        }
    }

    &-list {
        display: flex;
        flex-flow: wrap;
        width: 100%;

        li {
            width: 1 / 6 * 100%;
            box-sizing: border-box;
            margin-bottom: 24 / 1464 * 100%;
            padding: 0 12 / 1464 * 100%;
            @media screen and (max-width: 1024px) {
                width: 1 / 3 * 100%;
            }
        }

        .logo-item {
            background-color: #fff;
            @include zoomImg(220px, 110px);
        }
    }

    &-pagination {
        z-index: 20;
        text-align: center;
        margin-top: 25px;

        .swiper-pagination-bullet {
            width: 11px;
            height: 11px;
            background-color: #fff;
            border: 1px solid transparent;
            background-clip: content-box;
            padding: 3px;
            opacity: 1;
            margin: 0 6px !important;
            transition: all .4s;

            @media screen and (max-width: 1024px) {
                width: px2rem(18px);
                height: px2rem(18px);
                padding: px2rem(6px);
            }
        }

        .swiper-pagination-bullet-active {
            background-color: #e35152;
            border: 1px solid #e35152;
        }
    }
}

.about-target {
    @include bg;
    height: 960px;
    @media screen and (max-width: 1024px) {
        height: px2rem(1178px);
    }
    .wp {
        @include wp(750px);
    }

    &-content {
        color: #fff;
        border-bottom: 1px solid rgba(#fff, .15);
        text-align: center;
        padding: px2rem(190px) 0 42px;
        @media screen and (max-width: 1024px) {
            padding: px2rem(130px) 0 px2rem(80px);
        }
        .title {
            font-size: 46px;
            margin-bottom: 45px;
            @media screen and (max-width: 1024px) {
                margin-bottom: px2rem(50px);
                font-size: px2rem(66px);
            }
        }

        .s-title {
            font-size: 20px;
            margin-bottom: 13px;
        }

        .desc {
            font-size: 15px;
            line-height: 36px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(28px);
                line-height: 2;
            }
        }
    }

    &-counter {
        display: flex;
        margin-top: 60px;

        &-item {
            font-size: 15px;
            color: #fff;
            text-align: center;
            flex: 1;

            @media screen and (max-width: 1024px) {
                font-size: px2rem(26px);
            }

            .num {
                font-family: 'LeagueGothic';
                font-size: 70px;
                color: #fff;
                line-height: 1.8;
                user-select: none;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 1366px) {
                    font-size: 48px;
                    line-height: 1.5;
                }

                @media screen and (max-width: 1024px) {
                    font-size: px2rem(70px);
                    line-height: 1.8;
                }
            }
        }
    }
}

$chartColor: #2078ef;

.about-chart {
    // height: 1050px;
    box-sizing: border-box;
    padding: px2rem(110px) 0 px2rem(100px);
    @include bg;
    background-position: center;
    .wp {
        @include wp(1410px);
    }
    &-content {
        margin-top: 50px;
        img {
            width: 100%;
        }
    }
}

.about-strategy {
    height: 960px;
    @include bg;
    position: relative;
    @media screen and (max-width: 1024px) {
        height: auto;
    }
    &-wrapper {
        position: absolute;
        width: 431px;
        top: 210px;
        left: 1170 / 1903 * 100%;
        @media screen and (max-width: 1024px) {
            position: static;
            width: 100%;
            box-sizing: border-box;
            padding: px2rem(100px) px2rem(30px) px2rem(1000px);
        }
        .about-title {
            text-align: left;

            &::after {
                margin: 0;
            }
        }
    }

    &-content {
        margin-top: 38px;

        .item {
            color: #fff;
            line-height: 40px;
            margin-bottom: 20px;
            @media screen and (max-width: 1024px) {
                line-height: 2;
            }
            .title {
                font-size: 16px;
                font-weight: bold;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(28px);
                }
            }

            .desc {
                font-size: 16px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(28px);
                }
            }
        }
    }

    &-item {
        color: #fff;
        font-size: px2vw(15);
        font-family: Source Han Sans CN;
        line-height: px2vw(24);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        flex-flow: column;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(24px);
            line-height: 1.5;
        }
        &:nth-of-type(1),
        &:nth-of-type(5) {
            background-image: linear-gradient(184deg, #115fad 0%, #4b82c2 50%, #80a2d4 100%), linear-gradient(#f4b9a3, #f4b9a3);
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
            background-image: linear-gradient(183deg, #e01032 0%, rgba(224, 16, 50, .42) 58%, #e5bfb1 100%), linear-gradient(#f4b9a3, #f4b9a3);
            width: px2vw(216);
            height: px2vw(216);
            @media screen and (max-width: 1024px) {
                width: px2rem(280px);
                height: px2rem(280px);
            }
        }

        &:nth-of-type(1) {
            width: px2vw(216);
            height: px2vw(216);
            top: 185 / 960 * 100%;
            left: 313 / 1903 * 100%;
            @media screen and (max-width: 1024px) {
                width: px2rem(280px);
                height: px2rem(280px);
                top: 379 / 878 * 100%;
                left: 45 / 750 * 100%;
            }
        }
        &:nth-of-type(2) {
            top: 124 / 960 * 100%;
            left: 882 / 1903 * 100%;
            @media screen and (max-width: 1024px) {
                top: 379 / 878 * 100%;
                left: 422 / 750 * 100%;
            }
        }
        &:nth-of-type(3) {
            width: px2vw(300);
            height: px2vw(300);
            top: 340 / 960 * 100%;
            left: 532 / 1903 * 100%;
            background-image: linear-gradient(87deg, #e0062d 0%, #e24750 27%, #e48773 53%, #e5c0b2 100%), linear-gradient(#f4b9a3, #f4b9a3);
            font-size: 32px;
            line-height: 36px;
            @media screen and (max-width: 1024px) {
                width: px2rem(320px);
                height: px2rem(320px);
                font-size: px2rem(58px);
                line-height: 1.3;
                top: 530 / 878 * 100%;
                left: 208 / 750 * 100%;
            }
            p {
                font-weight: bold;
            }
        }

        &:nth-of-type(4) {
            top: 562 / 960 * 100%;
            left: 244 / 1903 * 100%;
            @media screen and (max-width: 1024px) {
                top: 707 / 878 * 100%;
                left: 45 / 750 * 100%;
            }
        }

        &:nth-of-type(5) {
            width: px2vw(228);
            height: px2vw(228);
            top: 547 / 960 * 100%;
            left: 844 / 1903 * 100%;
            @media screen and (max-width: 1024px) {
                width: px2rem(280px);
                height: px2rem(280px);
                top: 707 / 878 * 100%;
                left: 436 / 750 * 100%;
            }
        }
    }
}

.about-history {
    height: px2vw(960);
    // padding-bottom: 960 / 1903 * 100%;
    position: relative;
    @include bg;
    @media screen and (max-width: 1024px) {
        height: px2rem(1400px);
    }
    .wp {
        @include wp(1470px);
    }
    .about-title {
        text-align: left;
        &::after {
            margin: 0;
        }
    }
    &-wrapper {
        padding: px2rem(150px) 0 0;
        .wp {
            @include wp(1360px);
        }
    }
    &-swiper-box {
        height: px2vw(567);
        // padding-bottom: 567 / 1470 * 100%;
        transform: translateY(-26%);
        position: relative;
        @media screen and (max-width: 1440px) {
            transform: translateY(-25%);
        }
        @media screen and (max-width: 1366px) {
            transform: translateY(-27%);
        }
        @media screen and (max-width: 1024px) {
            height: auto;
            transform: translateY(0);
            margin-top: px2rem(120px);
            padding: 0 px2rem(60px);
        }
    }

    &-prev-btn,
    &-next-btn {
        position: absolute;
        color: #fff;
        font-size: 26px;
        cursor: pointer;
        outline: none;
        @media screen and (max-width: 1440px) {
            font-size: 23px;
        }
        @media screen and (max-width: 1024px) {
            font-size: px2rem(36px);
        }
    }
    &-prev-btn {
        left: -4%;
        bottom: 17%;
        @media screen and (max-width: 1440px) {
            left: -2%;
        }
        @media screen and (max-width: 1024px) {
            left: 0;
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &-next-btn {
        right: -4%;
        top: 42%;
        @media screen and (max-width: 1440px) {
            right: -2%;
        }
        @media screen and (max-width: 1024px) {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &-swiper {
        overflow: hidden;
        height: 100%;
        // width: 100%;
        // left: 0;
        // top: 0;
        // position: absolute;
        .swiper-slide {
            display: flex;
            align-items: flex-end;
        }
        .item {
            color: #fff;
            display: flex;
            flex-flow: column;
            .year {
                font-weight: bold;
                font-size: 30px;
                margin-bottom: 7px;
            }
            .info {
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 42px;
            }
            &::after {
                content: '';
                width: 12px;
                height: 12px;
                background-color: #fff;
                border-radius: 50%;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
}