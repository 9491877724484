.news-detail {
    @include pageBg;
    padding: px2rem(80px) 0 px2rem(110px);
    .wp {
        @include wp(1240px);
    }

    &-title {
        font-size: 28px;
        color: $fontColor333;
        text-align: center;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(44px);
        }
    }
    &-data {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .item {
            font-size: 14px;
            color: $fontColor666;
            &:first-child {
                margin-right: 15px;
            }
        }
    }
    &-info {
        font-size: 16px;
        color: $fontColor444;
        line-height: 36px;
        border-top: 1px solid rgba(#115fad, .1);
        margin-top: 40px;
        padding-top: 55px;
        img {
            max-width: 100%;
        }
        iframe {
            max-width: 100%;
        }
        video {
            max-width: 100%;
        }
    }

    &-navigation {
        background-color: #fff;
        font-size: 16px;
        color: #000;
        display: flex;
        // padding: 0 30px;
        line-height: 1;
        margin-top: 85px;
        border-top: 1px solid rgba(#bfbfbf, .2);
        @media screen and (max-width: 1024px) {
            padding: 0;
            font-size: px2rem(28px);
        }
        .iconfont {
            color: #6a6a6a;
            transition: all .3s;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: px2rem(18px) 0;
            transition: all .3s;
            flex: 1;
            color: #000;
            &:hover {
                color: #fff;
                background-color: $pageNavColor;
                .iconfont {
                    color: #fff;
                }
            }
        }
    }
    &-back-btn {
        flex: 1;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0 1px;
        border-style: solid;
        border-color: #ececec;
        .iconfont {
            font-size: 20px;
            margin-right: px2rem(25px);
        }
    }
    &-prev-btn,
    &-next-btn {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
            p {
                i {
                    display: none;
                }
                span {
                    display: none;
                }
            }
        }
    }
    &-prev-btn {
        .iconfont {
            margin-right: px2rem(25px);
            @media screen and (max-width: 1024px) {
                margin-right: px2rem(15px);
            }
        }
    }
    &-next-btn {
        .iconfont {
            margin-left: px2rem(25px);
            @media screen and (max-width: 1024px) {
                margin-left: px2rem(15px);
            }
        }
    }
    // @media screen and (max-width: 1024px) {
    //     &-next-btn {
    //         text-align: right;
    //         .iconfont {
    //             margin-left: px2rem(20px);
    //         }
    //     }
    // }

    .purchase-form {
        border-top: 1px solid rgba(#132154, .2);
        padding-top: 50px;
        margin-top: 75px;
        .page-common-title {
            &-en {
                font-size: 30px;
                font-family: PingFang SC, Microsoft YaHei;
            }
            &::after {
                width: 30px;
                height: 3px;
                background-color: #32659f;
            }
        }

        form {
            margin-top: 50px;
            .input-wrapper {
                display: flex;
                flex-flow: wrap;
            }
            p {
                font-size: 16px;
                color: $fontColor333;
                margin-bottom: 20px;
                em {
                    color: #e60032;
                }
            }
            .input-box {
                width: 50%;
                box-sizing: border-box;
                margin-bottom: 30px;
                @media screen and (min-width: 1024px) {
                    &:nth-child(odd) {
                        padding-right: 10px;
                    }
                    &:nth-child(even) {
                        padding-left: 10px;
                    }
                }
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
                input {
                    width: 100%;
                    height: 60px;
                    border: none;
                    background-color: #fff;
                    padding: 0 10px;
                    box-sizing: border-box;
                    &::placeholder {
                        font-size: 14px;
                        color: #7e7e7e;
                    }
                }
            }
            .textarea-box {
                width: 100%;
                textarea {
                    border: none;
                    resize: none;
                    background-color: #fff;
                    width: 100%;
                    height: 150px;
                    padding: 25px 10px;
                    box-sizing: border-box;
                    &::placeholder {
                        font-size: 14px;
                        color: #7e7e7e;
                    }
                }
            }
        }
        .captcha {
            // width: 300px;
            margin: 0 auto;
            margin-top: 25px;
        }
        &-btn {
            text-align: center;
            margin-top: 25px;
            button {
                width: px2rem(300px);
                // width: 100%;
                height: px2rem(60px);
                background-color: #276eb4;
                color: #fff;
                font-size: 16px;
                letter-spacing: px2rem(5px);
                border: none;
                outline: none;
                cursor: pointer;
                transition: .3s;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    height: px2rem(80px);
                    font-size: px2rem(28px);
                }
                &:hover {
                    background-color: $baseColor;
                }
            }
        }
    }
}